
import { defineComponent } from 'vue-demi';
import { computed } from 'vue-demi';
import { mapGetters } from "vuex";
import QuestionnaireTable from '@/components/questionnaire/modules/container/table/index.vue';

export default defineComponent({
  name: 'ExpertQuestionnairePivot',
  data() {
    return {
      questionnaire_id: this.$attrs._id,
      loaded: false,
    }
  },
  provide() {
    return {
      status: computed(() => false)
    }
  },
  mounted() {
    if (!this.questionnaire_id) return this.$router.push('/');
    this.getQuestionnaire();
  },
  computed: {
    ...mapGetters(["user", "questionnaire", "course"]),
  },
  methods: {
    async getQuestionnaire() {
      const result = await this.API.questionnaire.getPivotQuestionnaire(this.questionnaire_id);
      this.$store.commit('fillQuestionnaireData', { questionnaireData: result.data, course: result.data.course.type, role: this.user.role, pivot: true });
      this.loaded = true;
    },
  },
  components: {
    QuestionnaireTable
  }
})
